<template>
  <QAComponent></QAComponent>
</template>

<script>
  import QAComponent from '@/components/QA.vue';

  export default {
    name: 'Privacy',
    components: {
      QAComponent,
    },
  };
</script>
