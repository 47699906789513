<template>
  <v-card class="mt-2 pt-3 px-3 pb-1" elevation="0">
    <div class="mb-2">
      <span class="pl-2 ml-1" style="border-left: solid 8px orange; display: block; height: 30px">
        <span class="pt-1 font-weight-bold" style="display: inline-block">よくある質問</span>
      </span>
    </div>
    <v-expansion-panels focusable accordion class="text-body-2">
      <!-- <v-expansion-panel>
        <v-expansion-panel-header style="height: 40px" class="text-body-2"
          >レコセレについて</v-expansion-panel-header
        >
        <v-expansion-panel-content class="mx-0 pt-3">
          Coming Soon
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header style="height: 40px" class="text-body-2"
          >受け取りについて</v-expansion-panel-header
        >
        <v-expansion-panel-content class="mx-0 pt-3">
          Coming Soon
        </v-expansion-panel-content>
      </v-expansion-panel> -->
      <v-expansion-panel>
        <v-expansion-panel-header style="height: 40px" class="text-body-2">
          ご購入の流れ
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mx-0 pt-3">
          <v-img src="../assets/process.webp"></v-img>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header style="height: 40px" class="text-body-2">
          お支払方法について
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mx-0 pt-3">
          現時点ではクレジットカード、PayPayに対応しております。
          <br />
          <br />
          クレジットカード決済は、Visa・MasterCard・American Express・JCB・Diners
          Club・Discoverに対応しております。
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header style="height: 40px" class="text-body-2">
          セキュリティーについて
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mx-0 pt-3">
          レコセレの全てのページでSSLによる暗号化を行っております。
          <br />
          <br />

          また、クレジットカードの決済機能はStripe社が提供しており、会員データを安全に取り扱う事を目的とした業界の基準PCI
          DSS (Payment Card Industry Data Security Standard)に準拠しております。
          <strong>弊社は購入者様のカード情報を保持いたしません。</strong>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header style="height: 40px" class="text-body-2">
          お問い合わせ
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mx-0 pt-3">
          お問い合わせは全てLINE公式にて承ります。LINEのトーク画面で直接お問い合わせ内容をご送信ください。スタッフが返信いたします。
          <br />
          <div class="text-center">
            <v-btn class="my-1" elevation="0" outlined @click="staff_chat">
              LINEでお問い合わせ
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
  export default {
    name: 'QAComponent',
    methods: {
      staff_chat: function() {
        window.location.href = 'https://lin.ee/Q0QoAIw';
      },
    },
  };
</script>

<style>
  .v-expansion-panel:before {
    box-shadow: none !important;
  }
  .v-expansion-panel-header {
    min-height: 20px !important;
  }
  .v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
</style>
